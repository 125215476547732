import React, {useEffect, useState} from 'react';

import {useParams} from "react-router-dom";
import {Button, Col, Row, Spinner, ButtonGroup} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import APIService from "../services/api";
import {CashlessPaymentDetailed, CashlessPaymentStatus} from "../models/payment";
import Logo from '../components/Logo';
import PrintBox from '../components/PrintBox';

const PaymentPage = () => {
  const api = new APIService()
  let {paymentId} = useParams();

  const [payment, setPayment] = useState<CashlessPaymentDetailed | null>(null);

  useEffect(() => {
    getPayment();
  }, []);

  const getPayment = async () => {
    if (!paymentId) return;

    api.getCashlessPaymentDetails(paymentId)
      .then(_payment => setPayment(_payment))
      .catch(e => console.error(e));
  }

  if (payment === null) {
    return <div className='d-flex justify-content-center align-items-center' style={{height: '100vh'}}>
      <Spinner animation="border" variant="primary"/>
    </div>
  }

  const print = () => window.print();

  const isPayed = payment.status === CashlessPaymentStatus.PAYED;

  return (
    <>
      <Row className='py-2'>
        <Col>
          <LinkContainer to='/'>
            <Button variant="link">&#8249; Назад</Button>
          </LinkContainer>
        </Col>
      </Row>
      <Row className='d-flex justify-content-center'>
        <Col xs={3} className='d-flex flex-column align-items-center'>
          <ButtonGroup className='mb-3'>
            <Button variant="outline-primary" onClick={print}>Роздрукувати</Button>
            {!isPayed && <Button variant="outline-primary" onClick={getPayment}>Перевірити статус</Button>}
          </ButtonGroup>

          {!isPayed && (
            <PrintBox>
              <div className='d-flex flex-column align-items-center'>
                <Logo width={100} height={100}/>
                <h6>Код платежу:</h6>
                <h1 style={{fontSize: 60}}><b>{payment.code}</b></h1>
                <p><span>До сплати:</span> <b>{payment.amount} грн.</b></p>
              </div>
            </PrintBox>
          )}
          {isPayed && (
            <PrintBox printCopies={2}>
              <div className='d-flex flex-column'>
                <div className='d-flex justify-content-center'>
                  <Logo width={160} height={100}/>
                </div>
                <div className='d-flex flex-column align-items-center my-2'>
                  <h6><b>ВИДАТКОВА НАКЛАДНА</b></h6>
                  <h6>№{`${payment.id}`.padStart(5, '0')} від {payment.date}</h6>
                </div>
                {payment.items.map(item => (
                  <div className='d-flex flex-column align-content-around mt-2' key={item.id}>
                    <div className='d-flex flex-row justify-content-between'>
                      <span className='me-2 d-inline-block'>{item.goods.name}</span>
                      <span
                        className='d-flex justify-content-end'
                        style={{textAlign: 'right'}}
                      >{(item.price * item.quantity).toFixed(2)} грн.</span>
                    </div>
                    <div className='d-flex flex-row justify-content-between'>
                      <span>С/Н: {item.serial_number}</span>
                      <span
                        className='d-flex justify-content-end'
                        style={{textAlign: 'right'}}
                      >{item.quantity} {item.goods.unit}</span>
                    </div>
                    <div className='mt-2' style={{borderBottom: '1px solid #A8AAAE'}}/>
                  </div>
                ))}
                <div className='d-flex justify-content-between mt-2'>
                  <div>Всього:</div>
                  <div><b>{payment.amount.toFixed(2)} грн.</b></div>
                </div>
                <div className='d-flex justify-content-center my-5'>
                  <span>Отримав: ________________________</span>
                </div>
              </div>
            </PrintBox>
          )}
        </Col>
      </Row>
    </>
  );
};

export default PaymentPage;
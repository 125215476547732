import {Goods} from "./goods";

interface CashlessPayment {
  id: number,
  amount: number,
  status: string,
  timestamp: string,
}

interface CashlessPaymentItems {
  id: number,
  goods: Goods,
  quantity: number,
  serial_number: string,
  amount: number,
  price: number,
}

enum CashlessPaymentStatus {
  NEW = 1,
  PAYED = 2,
}


interface CashlessPaymentDetailed {
  id: number,
  amount: number,
  status: CashlessPaymentStatus,
  timestamp: string,
  code: string,
  date: string,
  items: CashlessPaymentItems[],
}

export type {
  CashlessPayment,
  CashlessPaymentDetailed,
}

export {
  CashlessPaymentStatus,
}

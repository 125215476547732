import React from 'react';
import {Form} from "react-bootstrap";

type Props = {
  type: string,
  placeholder: string,
  value: string | number,
  errors?: string[],
  onChange: (value: string) => void,
}

const FormControl: React.FC<Props> = (
  {
    type,
    placeholder,
    value,
    errors,
    onChange,
  }
) => {
  return (
    <>
      <Form.Control
        type={type}
        placeholder={placeholder}
        value={value || ''}
        isInvalid={errors && errors.length > 0}
        onChange={event => {
          event.persist();
          onChange(event.target.value);
        }}
      />
      {errors && errors.length && (
        <Form.Control.Feedback type="invalid">
          {errors[0]}
        </Form.Control.Feedback>
      )}
    </>
  );
};

const propsAreEqual = (prev: Props, next: Props) => prev.value === next.value && prev.errors === next.errors;

export default React.memo(FormControl, propsAreEqual);
import React, {useState, useMemo, useEffect} from "react";

import {
  Row,
  Col,
  Button,
  Form,
  Alert,
} from 'react-bootstrap';
import {LinkContainer} from "react-router-bootstrap";
import {useNavigate} from 'react-router-dom';

import APIService, {APIServiceError} from "../services/api";
import {Goods} from "../models/goods";

import FormControl from '../components/FormControl';
import AutocompleteFormControl from '../components/AutocompleteFormControl';

type Item = {
  id: number,
  goods: Goods | null,
  serial_number: string,
  price: number,
  quantity: number,
}

type ItemsError = {
  [key: number]: {
    goods?: string[],
    serial_number?: string[],
    quantity?: string[],
    price?: string[],
  }
}

type Errors = {
  items?: ItemsError,
}

const NewPaymentPage = () => {
  const api = new APIService();
  const [items, setItems] = useState<Item[]>([]);
  const [errors, setErrors] = useState<Errors>({});

  const navigate = useNavigate();

  useEffect(() => {
    !items.length && addNewItem();
  }, []);

  const addNewItem = () => {
    const generateServiceItemId = () => Math.floor(Math.random() * 10000);

    setItems(state => [...state, {
      id: generateServiceItemId(),
      goods: null,
      serial_number: '',
      quantity: 1,
      price: 0,
    }]);
  }

  const removeItem = (itemId: number) => {
    setItems(state => state.filter(i => i.id !== itemId));
  }

  const updateItem = (itemId: number, column: string, value: any) => {
    setItems(state => {
      return state.map(item => {
        if (item.id !== itemId) return item;
        return {...item, [column]: value};
      });
    });
  }

  const submitForm = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    const data: { [key: number]: object } = {};
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      data[item.id] = {
        goods: item.goods ? item.goods.id : null,
        serial_number: item.serial_number,
        quantity: item.quantity,
        price: item.price,
      };
    }

    let paymentId: number;
    try {
      paymentId = await api.addPayment({items: data});
    } catch (e) {
      console.error(e);
      if (e instanceof APIServiceError) {
        setErrors(e.data as ItemsError);
      }
      return;
    }
    navigate(`/payment/${paymentId}/`, {replace: true});
  }

  const totalAmount = useMemo(() => items.reduce((accumulator: number, item: Item) => {
    if (item.goods === null || !item.quantity) return accumulator;
    return (item.price * item.quantity) + accumulator;
  }, 0), [items]);

  const itemsErrors = errors.items || {};

  return (
    <>
      <Row className='py-2'>
        <Col>
          <LinkContainer to='/'>
            <Button variant="link">&#8249; Назад</Button>
          </LinkContainer>
        </Col>
      </Row>
      <Form onSubmit={submitForm}>
        {items.map(item => (
          <Row key={item.id} className='mb-3'>
            <Form.Group as={Col} className="mb-3">
              <AutocompleteFormControl<Goods>
                id={`goods_${item.id}`}
                autoFocus
                labelKey={'full_name'}
                placeholder='Товар'
                errors={itemsErrors[item.id]?.goods}
                onLoadOptions={api.getGoods.bind(api)}
                onSelectOption={(option) => {
                  const goods = option as Goods;
                  updateItem(item.id, 'goods', goods || null);
                  updateItem(item.id, 'price', goods.price || null);
                }}
              />
            </Form.Group>
            <Form.Group as={Col} xs={2}>
              <FormControl
                type="text"
                placeholder="Ціна"
                value={item.price}
                errors={itemsErrors[item.id]?.price}
                onChange={value => updateItem(item.id, 'price', value)}
              />
            </Form.Group>
            <Form.Group as={Col} xs={3}>
              <FormControl
                type="text"
                placeholder="Серійний номер"
                value={item.serial_number}
                errors={itemsErrors[item.id]?.serial_number}
                onChange={value => updateItem(item.id, 'serial_number', value)}
              />
            </Form.Group>
            <Col xs={2}>
              <Row>
                <Form.Group as={Col} xs={8}>
                  <FormControl
                    type="number"
                    placeholder="Кількість"
                    value={item.quantity}
                    errors={itemsErrors[item.id]?.quantity}
                    onChange={value => updateItem(item.id, 'quantity', value)}
                  />
                </Form.Group>
                <Col>
                  <Button
                    variant='outline-danger'
                    onClick={() => removeItem(item.id)}
                  ><b>&#215;</b></Button>
                </Col>
              </Row>
            </Col>
          </Row>
        ))}
        <Row>
          <Col>
            <Button variant="outline-primary" onClick={addNewItem}>+ Додати рядок</Button>
          </Col>
          <Col className='d-flex justify-content-end align-items-center me-3'>
            <span className='me-4'>До сплати: <b>{totalAmount}</b> грн.</span>
            <Button variant="outline-success" type='submit' disabled={totalAmount > 30000}>Зберегти</Button>
          </Col>
        </Row>
        {totalAmount > 5000 && (
          <Row>
            <Col offset={6} xs={12} className='d-flex justify-content-end mt-4'>
              <Alert variant='danger'> <b>Увага!</b> Термінал не приймає готівкою більше 5000 грн. за один
                платіж.</Alert>
            </Col>
          </Row>
        )}
        {totalAmount > 30000 && (
          <Row>
            <Col offset={6} xs={12} className='d-flex justify-content-end mt-4'>
              <Alert variant='danger'> <b>Увага!</b> Термінал не приймає більше 30000 грн. за один платіж.</Alert>
            </Col>
          </Row>
        )}
      </Form>
    </>
  )
}

export default React.memo(NewPaymentPage);
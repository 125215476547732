import IUser from '../models/user';
import {CashlessPayment, CashlessPaymentDetailed} from '../models/payment';
import {Goods} from '../models/goods';

type APIResponse = Response | null;

interface IAPIServiceError extends Error {
  data?: object,
}

class APIServiceError extends Error implements IAPIServiceError {
  data?: object;

  constructor(message: string, data?: object) {
    super(message);
    this.data = data;
  }
}

export default class APIService {
  static async authenticate(username: string, password: string) {
    const init: RequestInit = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({username, password}),
    }

    const response = await fetch('/token-auth/', init);

    switch (response.status) {
      case 200:
        const responseJson = await response.json();
        APIService.authToken = responseJson['token'];
        break;
      case 400:
        throw new APIServiceError('Невірний логін чи пароль.');
      default:
        throw new APIServiceError('Виникла помилка під час роботи запиту.');
    }
  }

  static get isAuthenticated(): boolean {
    return this.authToken !== null;
  }

  async getUser(): Promise<IUser> {
    const response = await this.get('/user/');
    if (response !== null && response.status === 200) {
      return await response.json();
    }
    throw new APIServiceError('Сталася помилка під час обробки запиту.');
  }

  async getCashlessPayments(q: string): Promise<CashlessPayment[]> {
    const response = await this.get(`/cashless/payments/?q=${q}`);
    if (response !== null && response.status === 200) {
      return await response.json();
    }
    throw new APIServiceError('Сталася помилка під час обробки запиту.');
  }

  async getGoods(q: string): Promise<Goods[]> {
    const response = await this.get(`/goods/?q=${q}`);
    if (response !== null && response.status === 200) {
      return await response.json();
    }
    throw new APIServiceError('Сталася помилка під час обробки запиту.');
  }

  async addPayment(data: object): Promise<number> {
    const response = await this.post('/cashless/payments/', data);
    if (response === null) throw new APIServiceError('Сталася помилка під час обробки запиту.');
    if (response.status === 200) {
      return await response.json();
    }
    const errors = await response.json();
    throw new APIServiceError('', errors);
  }

  async getCashlessPaymentDetails(paymentId: string): Promise<CashlessPaymentDetailed> {
    const response = await this.get(`/cashless/payment/${paymentId}/`);
    if (response !== null && response.status === 200) {
      return await response.json();
    }
    throw new APIServiceError('Сталася помилка під час обробки запиту.');
  }

  private get = async (url: string): Promise<APIResponse> => {
    const init: RequestInit = {headers: APIService.headers};
    return await this.makeResponse(url, init);
  }

  private post = async (url: string, body: object = {}): Promise<APIResponse> => {
    const init: RequestInit = {
      method: 'POST',
      headers: APIService.headers,
      body: JSON.stringify(body),
    }
    return await this.makeResponse(url, init);
  }

  private put = async (url: string, body: object): Promise<APIResponse> => {
    const init: RequestInit = {
      method: 'PUT',
      headers: APIService.headers,
      body: JSON.stringify(body),
    }
    return await this.makeResponse(url, init);
  }

  private delete = async (url: string, body: object): Promise<APIResponse> => {
    const init: RequestInit = {
      method: 'DELETE',
      headers: APIService.headers,
      body: JSON.stringify(body),
    }
    return await this.makeResponse(url, init);
  }

  private makeResponse = async (url: string, init: RequestInit): Promise<APIResponse> => {
    try {
      return await fetch(url, init);
    } catch (e) {
      return null;
    }
  }

  private static get authToken(): string | null {
    return localStorage.getItem('@cash_register_auth_token');
  }

  private static set authToken(token: string | null) {
    if (token) {
      localStorage.setItem('@cash_register_auth_token', token);
    } else {
      localStorage.removeItem('@cash_register_auth_token');
    }
  }

  private static get headers(): HeadersInit {
    return {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Token ${APIService.authToken}`,
    }
  }
}

export {
  APIServiceError,
}
import React, {FC} from 'react';

import './PrintBox.css';

type Props = {
  children: React.ReactNode,
  printCopies?: number,
}

const ReceiptBox: FC<Props> = (
  {
    printCopies = 1,
    children
  }
) => {

  return (
    <div className='PrintBox'>
      {Array.from({length: printCopies}).map((_, i) => (
        <div key={i} className={`${i > 0 && 'printable'}`} style={{position: 'relative'}}>
          {children}
          <div className="bottom-spacer"/>
        </div>
      ))}
    </div>
  );
};

export default ReceiptBox;

import React, {useState, useEffect} from 'react';

import {
  Row,
  Col,
  Button,
  Spinner,
  Alert,
  Table,
  FormControl,
} from 'react-bootstrap';

import {useNavigate} from 'react-router-dom';
import {LinkContainer} from 'react-router-bootstrap';

import APIService from "../services/api";
import IUser from '../models/user';
import {CashlessPayment} from "../models/payment";

const PaymentsListPage = () => {
  const api = new APIService();
  const navigate = useNavigate();
  const [user, setUser] = useState<IUser | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [payments, setPayments] = useState<CashlessPayment[] | null>(null);

  useEffect(() => {
    if (!APIService.isAuthenticated) return navigate('/login/', {replace: true});

    getUser().then(() => getPayments(searchValue));
  }, []);

  useEffect(() => {
    !searchValue && getPayments(searchValue);
  }, [searchValue]);

  const getUser = async () => {
    api.getUser()
      .then(_user => setUser(_user))
      .catch(e => console.log(e));
  }

  const getPayments = async (q: string) => {
    api.getCashlessPayments(q)
      .then(_payments => setPayments(_payments))
      .catch(e => console.log(e));
  }

  if (payments === null) {
    return <div className='d-flex justify-content-center align-items-center' style={{height: '100vh'}}>
      <Spinner animation="border" variant="primary"/>
    </div>
  }

  return (
    <>
      <Row className='d-flex justify-content-between py-4'>
        <Col sm={6} className='d-flex justify-content-end'>
          <FormControl
            type="number"
            placeholder="Номер платежу"
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
          />
          <Button
            className='ms-1'
            variant="outline-primary"
            onClick={() => getPayments(searchValue)}
          >Пошук</Button>
        </Col>
        <Col sm={2} className='d-flex justify-content-end'>
          <LinkContainer to='/add'>
            <Button variant="outline-primary">Новий платіж</Button>
          </LinkContainer>
        </Col>
      </Row>
      <Row>
        <Col className='d-flex justify-content-center'>
          {payments.length === 0 && (
            <Alert variant='light'>Відсутні платежі</Alert>
          )}
          {payments.length > 0 && (
            <Table>
              <thead>
              <tr>
                <th>#</th>
                <th>Сума</th>
                <th>Статус</th>
                <th>Час</th>
              </tr>
              </thead>
              <tbody>
              {payments.map(p => (
                <tr key={p.id}>
                  <td>
                    <LinkContainer to={`/payment/${p.id}/`}>
                      <a href="#">{`${p.id}`.padStart(6, '0')}</a>
                    </LinkContainer>
                  </td>
                  <td>{p.amount}</td>
                  <td>{p.status}</td>
                  <td>{p.timestamp}</td>
                </tr>
              ))}
              </tbody>
            </Table>
          )}
        </Col>
      </Row>
    </>
  )
}

export default PaymentsListPage;

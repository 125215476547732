import React, {useState, useEffect} from 'react';

import {
  Navbar,
  Container,
  Button,
  Alert,
} from 'react-bootstrap';

import {Outlet, useNavigate} from 'react-router-dom';

import Logo from '../components/Logo';
import APIService from "../services/api";
import IUser from '../models/user';

const IndexPage = () => {
  const api = new APIService();
  const navigate = useNavigate();
  const [user, setUser] = useState<IUser | null>(null);

  useEffect(() => {
    if (!APIService.isAuthenticated) return navigate('/login/', {replace: true});

    getUser();
  }, []);

  const getUser = async () => {
    let _user: IUser;
    try {
      _user = await api.getUser();
    } catch (e) {
      return;
    }
    setUser(_user);
  }

  if (user && !user.permissions.cashless) {
    return <Alert variant='light'>Відсутні права на перегляд платежів</Alert>
  }

  const logout = () => {
    if (!window.confirm('Ви впевнені, що хочете вийти?')) return;
    localStorage.removeItem('@cash_register_auth_token');
    navigate('/login/', {replace: true});
  }

  return (
    <Container fluid>
      <Navbar bg="light" expand="lg">
        <Container fluid>
          <Navbar.Brand>
            <Logo width={60} height={45}/>
          </Navbar.Brand>
          <Navbar.Collapse className="justify-content-end">
            {user && <Navbar.Text>{user.name}</Navbar.Text>}
            <Button variant="link" onClick={logout}>Вихід</Button>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container>
        <Outlet/>
      </Container>
    </Container>
  )
}

export default IndexPage;

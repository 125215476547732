import React from 'react';

import {BrowserRouter, Routes, Route} from 'react-router-dom';

import AuthPage from './pages/AuthPage';
import IndexPage from './pages/IndexPage';
import PaymentsListPage from './pages/PaymentsListPage';
import NewPaymentPage from './pages/NewPaymentPage';
import PaymentPage from './pages/PaymentPage';

import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <BrowserRouter>
      <main>
        <Routes>
          <Route path='/' element={<IndexPage/>}>
            <Route path='' element={<PaymentsListPage/>}/>
            <Route path='add/' element={<NewPaymentPage/>}/>
            <Route path='payment/:paymentId/' element={<PaymentPage/>}/>
          </Route>
          <Route path='/login/' element={<AuthPage/>}/>
        </Routes>
      </main>
    </BrowserRouter>
  );
}

export default App;

import React, {useState, useEffect} from 'react';
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import {Option} from "react-bootstrap-typeahead/types/types";

import {Form} from 'react-bootstrap';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import {Goods} from "../models/goods";

type Props<T extends Option> = {
  id: string,
  autoFocus: boolean,
  labelKey: string,
  placeholder: string,
  onLoadOptions: (q: string) => Promise<T[]>,
  onSelectOption: <T>(option: T | null) => void,
  errors?: string[],
}

const AutocompleteFormControl = <T extends Option>(
  {
    id,
    autoFocus,
    labelKey,
    placeholder,
    errors,
    onLoadOptions,
    onSelectOption,
  }: Props<T>
) => {
  const [options, setOptions] = useState<T[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getOptions = async (searchValue: string = '') => {
    setIsLoading(true);
    const _options = await onLoadOptions(searchValue);
    setOptions(_options);
    setIsLoading(false);
  }

  return (
    <>
      <AsyncTypeahead
        id={id}
        isLoading={isLoading}
        autoFocus={autoFocus}
        onSearch={getOptions}
        options={options}
        labelKey={labelKey}
        placeholder={placeholder}
        onChange={options => onSelectOption(options.length ? options[0] : null)}
        useCache={false}
        emptyLabel={<div>Не знайдено</div>}
        isInvalid={errors && errors.length > 0}
        inputProps={{
          id,
        }}
      />
      <div className={`${errors && errors.length > 0 && 'is-invalid'}`}/>
      {errors?.length && (
        <Form.Control.Feedback type="invalid">
          {errors[0]}
        </Form.Control.Feedback>
      )}
    </>
  );
}
const genericMemo: <T>(component: T, propsAreEqual: any) => T = React.memo;

const propsAreEqual = (prev: Props<any>, next: Props<any>) => prev.errors === next.errors;

export default genericMemo(AutocompleteFormControl, propsAreEqual);

import React, {memo, useState, useEffect} from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  Button,
} from "react-bootstrap";

import {useNavigate} from 'react-router-dom';

import Logo from '../components/Logo';
import APIService, {APIServiceError} from "../services/api";

const AuthenticationPage = () => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    error && setError(null);
  }, [username, password]);

  const authenticate = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();

    try {
      await APIService.authenticate(username, password);
    } catch (error) {
      console.log(error);
      return setError(error instanceof APIServiceError ? error.message : 'Помилка');
    }

    navigate('/', {replace: true});
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <div
            className='d-flex flex-column justify-content-center align-items-center'
            style={{paddingTop: '25vh'}}
          >
            <Row>
              <Col className='pb-4'>
                <Logo width={160} height={120}/>
              </Col>
            </Row>
            <Form>
              <Form.Group>
                <Form.Control
                  autoFocus
                  placeholder="Логін"
                  value={username}
                  onChange={e => setUsername(e.target.value)}
                />
              </Form.Group>
              <Form.Group className='py-2'>
                <Form.Control
                  type="password"
                  placeholder="Пароль"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
              </Form.Group>
              {error && (
                <div className='py-2'>
                  <Form.Text style={{color: '#DC3545', paddingBottom: '15px'}}>
                    {error}
                  </Form.Text>
                </div>
              )}
              <Button
                type="submit"
                variant="outline-primary"
                onClick={authenticate}
                disabled={!username || !password}
              >
                Увійти
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  )
};

export default memo(AuthenticationPage);
